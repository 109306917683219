import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-page" style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Terms and Conditions</h1>
      <p>Welcome to <strong>PlanMate</strong>. By using our application, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully before using our services.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using PlanMate, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree, you must refrain from using the app.
      </p>

      <h2>2. Use of the App</h2>
      <p>
        You agree to use PlanMate only for lawful purposes and in a way that does not infringe the rights of others or restrict or inhibit their use and enjoyment of the app. Prohibited actions include, but are not limited to:
      </p>
      <ul>
        <li>Using the app for fraudulent purposes.</li>
        <li>Transmitting harmful or malicious content.</li>
        <li>Attempting to gain unauthorized access to our systems.</li>
      </ul>

      <h2>3. Account Registration</h2>
      <p>
        To access certain features of PlanMate, you may need to create an account. You agree to provide accurate and complete information during registration and to keep your account credentials secure. You are responsible for all activity under your account.
      </p>

      <h2>4. Intellectual Property</h2>
      <p>
        All content, features, and functionality on PlanMate, including but not limited to text, graphics, logos, and software, are the intellectual property of PlanMate or its licensors. You may not reproduce, distribute, or create derivative works from this content without prior written permission.
      </p>

      <h2>5. Subscription and Payments</h2>
      <p>
        Certain features of PlanMate may require a subscription or payment. By subscribing, you agree to the applicable pricing and payment terms. All fees are non-refundable unless otherwise stated.
      </p>

      <h2>6. Limitation of Liability</h2>
      <p>
        PlanMate is provided on an "as is" and "as available" basis. We do not guarantee the accuracy, completeness, or reliability of the app's content or services. To the fullest extent permitted by law, PlanMate disclaims all liability for any direct, indirect, incidental, or consequential damages arising from your use of the app.
      </p>

      <h2>7. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your access to PlanMate at any time, without prior notice, if we believe you have violated these Terms and Conditions or engaged in unlawful behavior.
      </p>

      <h2>8. Modifications to Terms</h2>
      <p>
        We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and your continued use of the app constitutes acceptance of the revised terms.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        These Terms and Conditions are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from or related to these terms will be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or concerns about these Terms and Conditions, please contact us at:
      </p>
      <p>
        <strong>Email:</strong> support@planmate.com <br />
        <strong>Phone:</strong> +357 96304370     </p>

      <p style={{ textAlign: "center", marginTop: "30px" }}>
        <em>Last updated: January 2025</em>
      </p>
    </div>
  );
};

export default TermsAndConditions;