import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useTranslation } from 'react-i18next';


const TICKETS_QUERY = gql`
query TicketsQuery {
  tickets(order_by: {updated_at: desc}){
    id
    description
    created_at
    operator_id
    
    status
    subject
    updated_at
  }
}
`;





const Ticket = () => {
  const { t } = useTranslation();


  const { refetch: refetch_tickets } = useQuery(TICKETS_QUERY, {
    onCompleted: (data) => {
      const the_tickets = data.tickets;
      setTheTickets(the_tickets)
      for(let i=0; i < the_tickets.length; i++){

      }
    },
    onError: (err) => {
      console.log(err);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [TheTickets, setTheTickets] = useState([]);


  useEffect(
    () => {
      refetch_tickets();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            id="the_main_grid"
            style={{ backgroundColor: "white", margin: "0px", width: "100%" }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px 16px 0px 16px"
              }}
            >
              <Typography color="black" variant="h4">{t("Tickets")}</Typography>
              <Button style={{textTransform: "inherit", color: "white"}}variant="contained"
                onClick={()=>{
                  window.location.href = "/#/support/newticket";
                }}
              
              >{t("Create new ticket")}</Button>
            </Grid>
            <Grid item xs={12} style={{
                padding: "16px"}}>
              <TableContainer
                style={{ maxHeight: "600px", overflowΥ: "scroll" }}
                component={Paper}
              >
                <Table sx={{ minWidth: 500 }} stickyHeader size="small">
                  <TableHead>
                    <TableRow className="UsersTable">
                      <TableCell align="center">{t("Ticket ID")}</TableCell>
                      <TableCell align="center">{t("Last update")}</TableCell>
                      <TableCell align="center">{t("Subject")}</TableCell>
                      <TableCell align="center">{t("Assigned To (Op-id)")}</TableCell>
                      <TableCell align="center">{t("Status")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TheTickets.map((row, i) => (
                      <TableRow key={i} style={{cursor: "pointer",
                      backgroundColor: row.status === "Closed" ? "green": "inherit",
                      
                      
                      }} onClick={()=>{
                        
                        sessionStorage.setItem("ticket_id", row.id);
                        window.location.href = "/#/support/specificticket";
                      }}>
                        <TableCell 
                          style={{
                            color: row.status === "Closed" ? "white": "inherit",
                          }}
                          
                          align="center">{row.id}</TableCell>
                        <TableCell style={{
                            color: row.status === "Closed" ? "white": "inherit",
                          }}align="center">
                          { moment(row.updated_at).format("DD/MM/YYYY hh:mm")}
                        </TableCell>
                        <TableCell style={{
                            color: row.status === "Closed" ? "white": "inherit",
                          }}align="center">
                          {row.subject}
                        </TableCell>
                        <TableCell style={{
                            color: row.status === "Closed" ? "white": "inherit",
                          }}align="center">
                          {row.operator_id}
                        </TableCell>
                        <TableCell style={{
                            color: row.status === "Closed" ? "white": "inherit",
                          }}align="center">
                          {row.status}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default Ticket;
