import React from "react";
import { Navigate } from "react-router-dom";

//Pages
import Home from '../pages/Home/home';
import Cash from '../pages/Cash/cash';
import OnlineUsers from '../pages/OnlineUsers/onlineUsers';


import Banners from '../pages/Marketing/Banners';
import BonusMarketing from '../pages/Marketing/Bonus';
import Affiliates from '../pages/Marketing/Affiliates';
import EmailTemplates from '../pages/Marketing/EmailTemplates';

import Shops from '../pages/Shops/shops';
import Services from '../pages/Services/services';
import Staff from '../pages/Staff/staff';
import Reviews from '../pages/Reviews/reviews';
import Calendar from '../pages/Calendar/calendar';
import Users from '../pages/Users/users';

import NewService from '../pages/NewService/newService';
import NewStaff from '../pages/NewStaff/newStaff';
import NewAppointment from '../pages/NewAppointment/newAppointment';

import TopUsers from '../pages/TopGames/topUsers';
import TopServicesStaff from '../pages/TopGames/topServicesStaff';

import Operators from '../pages/Operators/operators';
import Messages from '../pages/Messages/messages';

import ShopDetails from '../pages/ShopDetails/shopDetails';
import UserDetails from '../pages/UserDetails/userDetails';

import NewOperator from '../pages/NewOperator/newOperator';
import NewShop from '../pages/NewShop/newShop';

import Login from '../pages/Login/BasicSignIn';
import Terms from '../pages/Login/termsandconditions';
import Privacy from '../pages/Login/privacypolicy';
import LoginOP from '../pages/Login/BasicSignInOperator';


import Profile from '../pages/Profile/profile';
import FAQ from '../pages/FAQ/FAQ';

import TopGames from '../pages/TopGames/topProvider';

import TopProviders from '../pages/TopGames/topProviders';



import Ticket from '../pages/Tickets/ticket';
import NewTicket from '../pages/Tickets/new_ticket';
import SpecificTicket from '../pages/Tickets/specific_ticket';
import ProvidersPerOp from '../pages/TopGames/topProvidersPerOp';

import LobbiesAll from '../pages/NewShop/lobbiesAll';
import CashLobby from '../pages/Cash/cashLobby';

import Test from '../pages/Cash/datePickerTest';

const authProtectedRoutes = [
  { path: "/home", component: <Home /> },
  { path: "/cash", component: <Cash /> },
  { path: "/onlineusers", component: <OnlineUsers /> },
  { path: "/banners", component: <Banners /> },
  { path: "/bonusMarketing", component: <BonusMarketing /> },
  { path: "/affiliates", component: <Affiliates /> },
  { path: "/emailTemplates", component: <EmailTemplates /> },
 

  { path: "/userDetails/:userId", component: <UserDetails /> },

  { path: "/shops", component: <Shops /> },
  { path: "/services", component: <Services /> },
  { path: "/staff", component: <Staff /> },
  { path: "/reviews", component: <Reviews /> },
  { path: "/calendar", component: <Calendar /> },
  { path: "/users", component: <Users /> },
  
  { path: "/newService", component: <NewService /> },
  { path: "/newAppointment", component: <NewAppointment /> },
  { path: "/newStaff", component: <NewStaff /> },
  { path: "/topUsers", component: <TopUsers /> },
  { path: "/topServicesStaff", component: <TopServicesStaff /> },

  { path: "/shopDetails/:userId", component: <ShopDetails /> },
  { path: "/operators", component: <Operators /> },
  { path: "/messages", component: <Messages /> },

  { path: "/newOperator", component: <NewOperator /> },
  { path: "/newShop", component: <NewShop /> },


  { path: "/Profile", component: <Profile /> },
  { path: "/FAQ", component: <FAQ /> },

  { path: "/topProvider", component: <TopGames /> },

  { path: "/topProviders", component: <TopProviders /> },
  { path: "/providersPerOp", component: <ProvidersPerOp /> },



  { path: "/support", component: <Ticket /> },
  { path: "/support/newticket", component: <NewTicket /> },
  { path: "/support/specificticket", component: <SpecificTicket /> },
  { path: "/lobbiesAll", component: <LobbiesAll /> },
  { path: "/cashLobby", component: <CashLobby /> },

  { path: "/test", component: <Test /> },

  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
  {
    path: "*",
    component: <Navigate to="/home" />
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/terms-and-conditions", component: <Terms /> },
  { path: "/privacy-policy", component: <Privacy /> },
  { path: "/loginop", component: <LoginOP /> },
];

export { authProtectedRoutes, publicRoutes };
