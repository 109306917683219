import React, { useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const OPERATOR_ID_QUERY = gql`
query OperatorIDQuery {
  shops{
    id
    operator_id
  }
}
`;



const CREATE_TICKET_MUTATION = gql`
mutation CreateTicketMutation($description: String = "", $operator_id: Int = -1, $service_provider_id: Int = -1, $status: String = "", $subject: String = "") {
  insert_tickets(
    objects: {subject: $subject, status: $status, service_provider_id: $service_provider_id, operator_id: $operator_id, description: $description}
  ) {
    affected_rows
  }
}
`;


const NewTicket = () => {
  const { t } = useTranslation();

  // const {data: data_operator_id } = useQuery(OPERATOR_ID_QUERY);


  const [set_create_new_ticket] = useMutation(CREATE_TICKET_MUTATION, {
    onCompleted: () => {
      window.location.href = "/#/support";
    },
    onError: (err) => {
      alert("Ticket was not created")
      console.log(err)
    },
  });

  const [TheSubject, setTheSubject] = useState("");
  const [TheDescription, setTheDescription] = useState("");

  function makeNewTicket() {
    
    const the_subject = TheSubject
    const the_description = TheDescription + "~"
    const the_status = "Pending"
    const the_service_provider_id = parseInt(localStorage.getItem('opID'), 10);

    const the_operator_id = 1


    set_create_new_ticket({
      variables: {
        subject: the_subject,
        description: the_description,
        status: the_status,
        service_provider_id: the_service_provider_id,
        operator_id: the_operator_id,
      },
    });
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            id="the_main_grid"
            style={{ backgroundColor: "white", margin: "0px", width: "100%" }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px",
                borderBottom: "1px solid grey"
              }}
            >
              <Typography color="black" variant="h4">
                {t("Create new ticket")}
              </Typography>
            </Grid>

            <Grid
              container
              style={{
                padding: "16px",
              }}
            >
              <Grid item xs={12} sm={2} style={{ display: "inline-flex" }}>
                <Typography
                  color="black"
                  variant="h6"
                  style={{ marginRight: "10px" }}
                >
                  {t("Subject")}:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} style={{ display: "inline-flex" }}>
                <TextField
                  fullWidth
                  label="A brief of your issue ticket"
                  placeholder="Subject"
                  value={TheSubject}
                  onChange={(e)=>{
                    setTheSubject(e.target.value)
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                padding: "16px",
              }}
            >
              <Grid item xs={12} sm={2} style={{ display: "inline-flex" }}>
                <Typography
                  color="black"
                  variant="h6"
                  style={{ marginRight: "10px" }}
                >
                  {t("Description")}:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10} style={{ display: "inline-flex" }}>
                <TextField
                  fullWidth
                  multiline
                  label="Describe your issue here in details"
                  rows={4}
                  placeholder="Description"
                  value={TheDescription}
                  onChange={(e)=>{
                    setTheDescription(e.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: "inline-flex", marginBottom: "20px",padding: "16px",flexDirection: "row-reverse" }}>
            <Button variant="contained" style={{textTransform: "inherit", color: "white" }}
                   onClick={()=>{
                    makeNewTicket()
                  }}
              
              >{t("Save")}</Button>
            <Button variant="contained" style={{textTransform: "inherit", color: "white",backgroundColor: "#990000", marginRight: "5px"}}
                  onClick={()=>{
                    window.location.href = "/#/support";
                  }}
              
              >{t("Back")}</Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default NewTicket;
