import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page" style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Privacy Policy</h1>
      <p>
        Welcome to our application. Protecting your personal data and privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, and safeguard your information.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Name, email address, phone number, and other details you provide during registration or use of the application.
        </li>
        <li>
          <strong>Location Data:</strong> If you enable location-based services, we may collect your location to improve service functionality.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use the app, such as pages visited, actions taken, and error logs.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use your information for the following purposes:</p>
      <ul>
        <li>To provide and improve our services.</li>
        <li>To personalize your experience.</li>
        <li>To communicate with you, including sending notifications and updates.</li>
        <li>To analyze and improve app functionality.</li>
        <li>To comply with legal obligations.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell or rent your personal information to third parties. We may share your information in the following cases:
      </p>
      <ul>
        <li>With service providers who assist in operating the application.</li>
        <li>As required by law or to protect our legal rights.</li>
        <li>With your consent.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We take appropriate technical and organizational measures to protect your data from unauthorized access, loss, misuse, or alteration. However, no system is completely secure, and we cannot guarantee absolute security.
      </p>

      <h2>5. Your Rights</h2>
      <p>You have the following rights regarding your data:</p>
      <ul>
        <li>Access to the information we hold about you.</li>
        <li>Correction of inaccurate or incomplete data.</li>
        <li>Deletion of your personal information, subject to legal and operational requirements.</li>
        <li>Restriction of or objection to data processing.</li>
        <li>Data portability to another service provider.</li>
      </ul>

      <h2>6. Cookies and Tracking</h2>
      <p>
        We may use cookies and similar tracking technologies to enhance your experience. These technologies help us analyze usage patterns and improve the app.
      </p>

      <h2>7. Third-Party Services</h2>
      <p>
        Our app may include links to third-party services. We are not responsible for the privacy practices of these third parties, and we encourage you to review their policies.
      </p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be communicated through the app or other appropriate means. Please review the policy periodically for updates.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or your data, please contact us at:
      </p>
      <p>
      <strong>Email:</strong> support@planmate.com <br />
      <strong>Phone:</strong> +357 96304370
      </p>

      <p style={{ textAlign: "center", marginTop: "30px" }}>
        <em>Last updated: January 2025</em>
      </p>
    </div>
  );
};

export default PrivacyPolicy;