import React, { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const TICKETS_QUERY = gql`
  query TicketsQuery($id: Int = -1) {
    tickets_by_pk(id: $id) {
      id
      description
      created_at
      operator_id
      status
      subject
      updated_at
    }
  }
`;

const UPDATE_TICKET_MUTATION = gql`
  mutation UpdateTicketMutation($id: Int = -1, $description: String = "") {
    update_tickets(
      where: { id: { _eq: $id } }
      _set: { description: $description }
    ) {
      affected_rows
    }
  }
`;

let tick_id = -1;

const SpecificTicket = () => {
  const { t } = useTranslation();

  const [TheTicket, setTheTicket] = useState({});
  const [TheDescription, setTheDescription] = useState([]);
  const [TheAnswer, setTheAnswer] = useState("");

  const { refetch: refetch_ticket } = useQuery(TICKETS_QUERY, {
    variables: {
      id: tick_id,
    },
    onCompleted: (data) => {
      const the_tick = data.tickets_by_pk;
      setTheTicket(the_tick);

      const the_description = the_tick.description;

      const result = [];
      let start = 0; // Start of the current substring.

      for (let i = 0; i < the_description.length; i++) {
        if (the_description[i] === "~" || the_description[i] === "^") {
          const substring = the_description.substring(start, i); // Capture the substring, including the symbol.
          result.push({
            cashier: the_description[i] === "~", // If it's a '~', set symbol to true; otherwise, it'll be false.
            substring: substring,
          });
          start = i + 1; // Update the start of the next substring.
        }
      }

      // Check if there's any leftover substring without a trailing symbol.
      if (start < the_description.length) {
        result.push({
          cashier: false,
          substring: the_description.substring(start),
        });
      }

      setTheDescription(result);
    },
    onError: (err) => {
      console.log(err);
    },
    pollInterval: 2000,
  });

  const [set_update_ticket] = useMutation(UPDATE_TICKET_MUTATION, {
    onCompleted: () => {
      refetch_ticket({
        id: tick_id,
      });
      setTheAnswer("")
    },
    onError: (err) => {
      alert("Ticket was not updated");
      console.log(err);
    },
  });

  function updateTicket() {
    const the_description = TheTicket.description + TheAnswer + "~";
    const the_ticket_id = TheTicket.id;

    set_update_ticket({
      variables: {
        id: the_ticket_id,
        description: the_description,
      },
    });
  }

  useEffect(
    () => {
      tick_id = parseInt(sessionStorage.getItem("ticket_id"));
      refetch_ticket({
        id: tick_id,
      });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <div className="page-content">
        {TheTicket !== {} && (
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
              id="the_main_grid"
              style={{ backgroundColor: "white", margin: "0px", width: "100%" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                  borderBottom: "1px solid grey",
                }}
              >
                <Typography color="black" variant="h4">
                  {t("Ticket") + ": " + TheTicket.id}
                </Typography>
              </Grid>

              <Grid
                container
                style={{
                  padding: "16px",
                }}
              >
                <Grid item xs={12} sm={9} style={{ display: "inline-flex" }}>
                  <Typography
                    color="black"
                    variant="h5"
                    style={{ marginRight: "10px" }}
                  >
                    {t("Subject")}:
                  </Typography>
                  <Typography
                    color="black"
                    variant="h6"
                    style={{
                      marginRight: "10px",
                      alignItems: "center",
                      display: "inline-flex",
                    }}
                  >
                    {TheTicket.subject}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} style={{ display: "inline-flex" }}>
                  <Typography
                    color="black"
                    variant="h5"
                    style={{ marginRight: "10px" }}
                  >
                    {t("Status")}:
                  </Typography>
                  <Typography
                    color="black"
                    variant="h6"
                    style={{
                      marginRight: "10px",
                      alignItems: "center",
                      color:
                        TheTicket.status === "Pending"
                          ? "blue"
                          : TheTicket.status === "Closed"
                          ? "green"
                          : "inherit",
                      display: "inline-flex",
                    }}
                  >
                    {TheTicket.status}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  padding: "16px",
                  borderTop: "1px solid grey",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{}}
                  style={{ display: "inline-flex", paddingBottom: "20px" }}
                >
                  <Typography
                    color="black"
                    variant="h5"
                    style={{ marginRight: "10px" }}
                  >
                    {t("Conversation")}:
                  </Typography>
                </Grid>
                <Grid container style={{ borderBottom: "1px solid grey", paddingBottom: "20px" }}>
                  {TheDescription.map((desc, i) => (
                    <Grid item xs={12}>
                      <Grid
                        container
                        style={{
                          flexDirection: desc.cashier ? "row-reverse" : "row"
                        }}
                      >
                        <Grid
                          item
                          xs={9}
                          style={{
                            display: "inline-flex",
                            padding: "0.25vh 5vw",
                            flexDirection: desc.cashier ? "row-reverse" : "row"
                          }}
                        >
                          <Typography
                            color="black"
                            variant="h8"
                            className="ticketConversation"
                            style={{
                              backgroundColor: !desc.cashier ? "#333331" : "#990000",
                             
                              whiteSpace: "pre-line",
                              color: "white" ,
                              borderRadius: "15px",
                              padding: "0.5vh 5vw"
                            }}
                          >
                            {desc.substring}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{ marginTop: "25px", display: "inline-flex" }}
                >
                  <Typography
                    color="black"
                    variant="h6"
                    style={{ marginRight: "10px" }}
                  >
                    {t("Answer")}:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  style={{ marginTop: "25px", display: "inline-flex" }}
                >
                  <TextField
                    fullWidth
                    multiline
                    label="Answer in this ticket"
                    rows={4}
                    disabled={TheTicket.status === "Closed"}
                    placeholder="Answer"
                    value={TheAnswer}
                    onChange={(e) => {
                      setTheAnswer(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "inline-flex",
                    marginBottom: "20px",
                    padding: "16px",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button
                    disabled={TheTicket.status === "Closed"}
                    variant="contained"
                    style={{ textTransform: "inherit", color: "white" }}
                    onClick={() => {
                      updateTicket();
                    }}
                  >
                    {t("Send")}
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "inherit",
                      color: "white",
                      backgroundColor: "#990000",
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      window.location.href = "/#/support";
                    }}
                  >
                    {t("Back")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </React.Fragment>
  );
};

export default SpecificTicket;
